<template>
  <ReturnedGoodsOfUserListUser
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :typeReturned="typeReturned"
      :navTabs="navTabs"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
      @changeTab="changeTab"
  />
</template>

<script>
  import ReturnedGoodsOfUserListUser from "./ReturnedGoodsOfUserListUser/ReturnedGoodsOfUserTableUser";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
  import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
  import {RETURN_GOODS_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "ReturnedGoodsOfUserList",
    components: {
      ReturnedGoodsOfUserListUser,
    },

    mixins: [routeFilter, returnGoodsMixin],

    watch: {
      routerReturnType(type) {
        if(type){
          if (this.isAdmin && (this.$route.meta.type === RETURN_GOODS_TYPES.PROCESSED_RETURNS || this.$route.meta.type === RETURN_GOODS_TYPES.UNPROCESSED_RETURNS) &&
          this.localNavTabs === null) {
            this.getWarehouseStorages().then(() => {
              if(this.$route.query.type !== undefined){
                this.skipRadioChecked(this.navTabs, 'active')
                this.navTabs[this.$route.query.type].active = true
              }
              else {
                this.$route.query.type = this._.find(this.navTabs, {active: true})?.name || ''
              }
              this.reload()
            })
          }
          else if (this.isAdmin && (this.$route.meta.type === RETURN_GOODS_TYPES.PROCESSED_RETURNS || this.$route.meta.type === RETURN_GOODS_TYPES.UNPROCESSED_RETURNS)) {
            this.navTabs = this.localNavTabs
            if (this.$route.query.type === undefined) {
              //fix for situations where we go to other page that uses this component with 2nd or 3rd tab active
              let firstFlag = false
              Object.keys(this.navTabs).forEach(tab => {
                if (!firstFlag) {
                  this.navTabs[tab].active = true
                  firstFlag = true
                }
                else {
                  this.navTabs[tab].active = false
                }
              })
              this.$route.query.type = this._.find(this.navTabs, {active: true})?.name || ''
            }
            this.reload()
          }
          else {
            this.navTabs = {}
            this.reload()
          }
        }
      },
    },

    computed: {
      routerReturnType() {
        return this.$route.meta.type
      },
    },

    data(){
      return {
        forPage: this.$store.getters.getReturnGoodsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterTrackingNumber: '',
        filterOriginTrackingNumber: '',
        filterTypeProcessed: '',
        filterTypeProcessedName: '',
        filterType: '',

        typeReturned: null,

        navTabs: {
        },
        localNavTabs: null,

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      if (this.isAdmin && (this.$route.meta.type === RETURN_GOODS_TYPES.PROCESSED_RETURNS || this.$route.meta.type === RETURN_GOODS_TYPES.UNPROCESSED_RETURNS)) {
        this.getWarehouseStorages().then(() => {
          if(this.$route.query.type !== undefined){
            this.skipRadioChecked(this.navTabs, 'active')
            this.navTabs[this.$route.query.type].active = true
          }
          else {
            this.$route.query.type = this._.find(this.navTabs, {active: true})?.name || ''
          }
          this.filter()
        })
      }
      else {
        this.filter()
      }
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          trackingNumber: this.$route.query.trackingNumber,
          originTrackingNumber: this.$route.query.originTrackingNumber,
          typeProcessed: this.$route.query.typeProcessed,
          typeProcessedName: this.$route.query.typeProcessedName,
          type: this.$route.query.type,
        }
      },

      filter(next = false) {

        this.typeReturned = this.$route.meta.type

        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextReturnGoodsPage', true)
        this.$store.dispatch('fetchReturnGoods', url).then(() => {
          this.$store.commit('setNextReturnGoodsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName', 'typeProcessedName', 'type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'ReturnParcelId',
              filterUserId: 'ReturnParcelUserId',
              filterTrackingNumber: 'ReturnParcelTrackingNumber',
              filterOriginTrackingNumber: 'byStarterTrack',
              filterType: 'WarehouseStorageId',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('byDeliveryDate', date)
        }

        if(this.isTypeProcessed(this.typeReturned)){
          myQuery.where('ReturnParcelStatus', 'completed')
        }

        if(this.isTypeUnProcessed(this.typeReturned)){
          myQuery.where('ReturnParcelStatus', 'in_process')
        }

        if(this.isTypeTrash(this.typeReturned)){
          myQuery.where('ReturnParcelTrashedAt', '1')
        }

        if(this.filterTypeProcessed === 'meest'){
          myQuery.where('returnDocumentDeliveryServiceId', '21')
        }

        if(this.filterTypeProcessed === 'fbm'){
          myQuery.where('byOrderFbm', '1')
        }

        if(this.filterTypeProcessed === 'inbound'){
          myQuery.where('byOrderInbound', '1')
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          let warehouses = this.getRespPaginateData(response)
          let warehousesLocal = {}
          let activeTab = Number(this.$route.query?.warehouse) || 1

          warehouses.map((item) => {
            let warehouseItem = {}
            warehouseItem['id'] = item.id
            warehouseItem['name'] = item.id
            warehouseItem['label'] = item.name
            warehouseItem['warehouse_id'] = item.warehouse_id
            warehouseItem['active'] = activeTab === item.id
            warehousesLocal[item.id] = this._.clone(warehouseItem)
          })

          this.navTabs = warehousesLocal
          this.localNavTabs = warehousesLocal
        })
      },

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setReturnGoods', {returnGoods: []})
        this.$store.commit('setReturnGoodsCommonList', {returnGoodsCommonList: []})
      }, 100)
    },

  }
</script>

<style scoped>

</style>