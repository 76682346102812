import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../../globalModels/Files";

export function RequestReturnGoods() {

  this.checkValid = checkValid

  this.data = {
    comment: '',
    Files: new Files({
      maxSizeFiles: 3,
      maxCountFiles: 1
    }),
  }

  this.validation = {
    comment: false,
  }

  this.validationTranslate = {
    comment: '',
  }

  this.validationTxt = {
    comment: false,
  }

  /**
   * Getters
   */
  this.getComment = () => {
    return this.data.comment
  }

  /**
   * Setters
   */
  this.setComment = (val) => {
    this.data.comment = val
  }
}

/**
 * Global Setters
 */

RequestReturnGoods.prototype.setItem = function (item) {
  this.setComment(item.comment)
}

/**
 * Prepare Data
 */

RequestReturnGoods.prototype.prepareSave = function () {
  return {
    'comment': this.getComment(),
  }
}


RequestReturnGoods.prototype.prepareFilesData = function (id) {
  let readerArray = []
  this.data.Files.getFiles().map(item => {

    let reader = new FormData();
    reader.append("file", item.file);
    reader.append("return_parcel_request_id", id)
    readerArray.push(reader)
  })
  return readerArray
}

/**
 * Validations
 */

RequestReturnGoods.prototype.firstValidation = function () {

  let validationItems = {
    comment: this.getComment(),
  }

  let validationOptions = {
    comment: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions) && this.data.Files.firstValidation())
}
