import { render, staticRenderFns } from "./ReturnedGoodsOfUserHead.vue?vue&type=template&id=e32c8962&scoped=true"
import script from "./ReturnedGoodsOfUserHead.vue?vue&type=script&lang=js"
export * from "./ReturnedGoodsOfUserHead.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e32c8962",
  null
  
)

export default component.exports