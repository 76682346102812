<template>
  <div>
    <ReturnedGoodsOfUserHead
        :typeReturned="typeReturned"
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        :navTabs="navTabs"
        @changeTab="(data) => $emit('changeTab', data)"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <ReturnedGoodsOfUserFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :typeReturned="typeReturned"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ReturnedGoodsOfUserTable
          :countFilterParams="countFilterParams"
          :typeReturned="typeReturned"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import ReturnedGoodsOfUserHead from "./ReturnedGoodsOfUserHead/ReturnedGoodsOfUserHead";
  import ReturnedGoodsOfUserTable from "./ReturnedGoodsOfUserTable/ReturnedGoodsOfUserTable";
  import ReturnedGoodsOfUserFilter from "./ReturnedGoodsOfUserFilter/ReturnedGoodsOfUserFilter";

  export default {
    name: "ReturnedGoodsOfUserListUser",

    components: {
      ReturnedGoodsOfUserHead,
      ReturnedGoodsOfUserTable,
      ReturnedGoodsOfUserFilter,
    },

    props: {
      typeReturned: String,
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

    }
  }
</script>

<style scoped>

</style>


