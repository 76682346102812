import {RETURN_GOODS_TYPES} from "@/staticData/staticVariables";

export const returnGoodsMixin = {

  data() {
    return {
      images: -1,
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    }
  },

  methods: {

    isTypeTrash(type) {
      return type === RETURN_GOODS_TYPES.TRASH
    },

    isTypeProcessed(type) {
      return type === RETURN_GOODS_TYPES.PROCESSED_RETURNS
    },

    isTypeUnProcessed(type) {
      return type === RETURN_GOODS_TYPES.UNPROCESSED_RETURNS
    },

    isTypeMyReturns(type) {
      return type === RETURN_GOODS_TYPES.MY_RETURNS
    },

    attachUser(returnId, userId, closePopup = false) {
      let data = {
        'return_parcel_id': returnId,
        'user_id': userId,
      }

      this.$store.dispatch('attachUserReturnGoods', data).then(response => {
        if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            this.$emit('reload')

            if(closePopup){
              this.$emit('close')
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * FORBIDDEN CODE
           */
          case this.$store.getters.GET_ERRORS.FORBIDDEN_CODE: {
            this.checkAccess2faAt()
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    save(edit = false) {
      if (!this.RG.firstValidation()) return

      let data = this.RG.prepareSave(edit)

      let typeDispatch = 'createReturnGoods'

      if (edit) {
        typeDispatch = 'updateReturnGoods'
        data = {
          id: this.RG.data.Order.getId(),
          data: data,
        }
      }

      this.$store.dispatch(typeDispatch, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            // if (this.RG.data.Files.getFiles().length > 0) {
            if (edit) {
              this.addFiles(data.id, edit)
            } else {
              this.addFiles(this.getRespData(response)?.id, edit)
            }
            // }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    async addFiles(id, edit, partialEdit = false) {
      if (this.RG.data.Files.getFiles().length > 0) {
        await this.addCaseFiles(id)
      }

      if (edit && !partialEdit) {
        this.openNotify('success', 'common_notificationRecordChanged')
      } else if (!partialEdit) {
        this.openNotify('success', 'common_notificationRecordCreated')
      }

      if (!partialEdit)
        this.$router.push(this.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.PROCESSED_RETURNS)
    },


    async addCaseFiles(id) {
      let data = this.RG.prepareFilesData(id)

      if (data.length === 0) return Promise.resolve()

      for (const file of data) {
        await this.$store.dispatch('addReturnGoodsFile', file).then(response => {
          this.responseFilesProcessing(response)
          return response
        })
      }
    },

    responseFilesProcessing(response) {
      if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
      switch (this.getResponseStatus(response)) {
        /**
         * Success
         */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          break
        }
        /**
         * Validation Error
         */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
        /**
         * Undefined Error
         */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }
    },

    async downloadFileFromArray(array) {
      let downloadedFilesArray = []
      for (const item of array) {
        if (item?.type === 'small_image') {
          await this.getFileFromServer(item, 'image').then(document => {
            downloadedFilesArray.push(document)
          })
        }

        if (item?.extension === 'pdf') {
          await this.getFileFromServer(item, 'pdf').then(document => {
            downloadedFilesArray.push(document)
          })
        }
      }
      return downloadedFilesArray
    },

    getFileFromServer(file, type = null){
      if(!file) return

      let localItem = file

      return this.$store.dispatch('getFileFromServer', file.id)
        .then((response) => {
          let fileBase64 = response
          switch (type) {
            case 'image':
              localItem.typeBase64 = `image`
              localItem.base64 = fileBase64[file.id]
              break
            default:
              localItem.base64 = fileBase64[file.id]
              break
          }

          return localItem
        })

    },

    removeReturnedPackage(id, index) {
      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteReturnGoods', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            let returnedPackage = this.$store.getters.getReturnGoods
            returnedPackage.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    },

    toTrashReturnedPackage(id, index, to = true) {
      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {
        this.$store.dispatch('toTrashReturnGoods', {id: id, data: {trash: to}}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            let returnedPackage = this.$store.getters.getReturnGoods
            returnedPackage.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    },


    removeDocument(item) {
      let targetFile = item.id

      //delete big image
      if (item.type === 'small_image') {
        targetFile = item.id + 1
      }

      this.$store.dispatch('removeUserDocument', targetFile).then(() => {

        let files = this.RG.data.Files
        let updatedFiles = this._.remove(files.data.downloadFiles, function(n) {
          return n.id === item.id
        })
        files.getDownloadFiles(updatedFiles)
        this.images = item.id

      })
    },

    openPdf(base64) {
      this.globalOpenPdfBase64(base64)
    },
    handleHide() {
      this.visible = false
    },
    show() {
      this.visible = true
    },

    showSingle(id) {
      this.$store.dispatch('getFileFromServer', id).then((response) => {
        this.imgs = []
        this.imgs.push({
          title: 'img',
          src: `data:image/png;base64,` + response[id]
        })
        this.show()
      })
    },
  }

}