<template>
  <modal
      @close="$emit('close')"
      class="what-order-create-modal what-order-create what-order-create--shipment"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_whatDoYouNeedPopup'])"></div>
      {{ $t('ordersInbound_whatDoYouNeedPopup.localization_value.value') }}
      </div>
    </template>
    <template slot="body">

<!--           v-if="(_.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_FBM) ||-->
<!--              _.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_FBA)) && (fbm || fba)"-->
<!--      <div class="section-label large color-brown"-->
<!--           v-if="(_.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_FBM) ||-->
<!--              _.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_RETURNS)) && (fbm || fbmReturn)"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_whatDoYouNeedPopup'])"></div>-->
<!--        {{ $t('ordersInbound_shipment.localization_value.value') }}-->
<!--      </div>-->

      <div class="what-order-create__list custom-row">
        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_FBM) && fbm"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_FBM', 'ordersInbound_FBMDesk'])"></div>
          <router-link
              :to="fbm"
              :target="linkTarget"
              class="what-order-create__link flex-column"
          >
            <div class="what-order-create__name">
              {{ $t('ordersInbound_FBM.localization_value.value') }}
            </div>
            <div class="what-order-create__desk">
              {{ $t('ordersInbound_FBMDesk.localization_value.value') }}
            </div>
          </router-link>
        </div>

        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_FBM_GENERAL) && fbmReturn"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_FBMReturns'])"></div>
          <router-link
              :to="fbmReturn"
              :target="linkTarget"
              class="what-order-create__link flex-column"
          >
            <div class="what-order-create__name">
              {{ $t('ordersInbound_FBMReturn.localization_value.value') }}
            </div>
<!--            <div class="what-order-create__desk">-->
<!--              {{ $t('ordersInbound_FBMDesk.localization_value.value') }}-->
<!--            </div>-->
          </router-link>
        </div>

<!--        <div class="what-order-create__item custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
<!--             v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_FBA) && fba"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_FBA', 'ordersInbound_FBADesk'])"></div>-->
<!--          <router-link-->
<!--              :to="fba"-->
<!--              class="what-order-create__link flex-column"-->
<!--          >-->
<!--            <div class="what-order-create__name">-->
<!--              {{ $t('ordersInbound_FBA.localization_value.value') }}-->
<!--            </div>-->
<!--            <div class="what-order-create__desk">-->
<!--              {{ $t('ordersInbound_FBADesk.localization_value.value') }}-->
<!--            </div>-->
<!--          </router-link>-->
<!--        </div>-->
      </div>


<!--      <template v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_INBOUND_RETURNS) && (fbaReturn || fbmReturn)">-->

<!--        <div class="section-label large color-brown mt-4"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_Returns'])"></div>-->
<!--          {{ $t('ordersInbound_Returns.localization_value.value') }}-->
<!--        </div>-->

<!--        <div class="what-order-create__list custom-row">-->
<!--          <div class="what-order-create__item custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
<!--               v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_FBM_GENERAL) && fbmReturn"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_FBMReturns', 'ordersInbound_FBMDesk'])"></div>-->
<!--            <router-link-->
<!--                    :to="fbmReturn"-->
<!--                    class="what-order-create__link flex-column"-->
<!--            >-->
<!--              <div class="what-order-create__name">-->
<!--                {{ $t('ordersInbound_FBMReturn.localization_value.value') }}-->
<!--              </div>-->
<!--              <div class="what-order-create__desk">-->
<!--                {{ $t('ordersInbound_FBMDesk.localization_value.value') }}-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </div>-->

<!--          <div class="what-order-create__item custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
<!--               v-if="_.has(this.currentPermissions, PERMISSIONS.ORDER_FBA_GENERAL) && fbaReturn"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" v-if="isAdmin" @click="editTranslate(['ordersInbound_FBAReturn', 'ordersInbound_FBADesk'])"></div>-->
<!--            <router-link-->
<!--                    :to="fbaReturn"-->
<!--                    class="what-order-create__link flex-column"-->
<!--            >-->
<!--              <div class="what-order-create__name">-->
<!--                {{ $t('ordersInbound_FBAReturn.localization_value.value') }}-->
<!--              </div>-->
<!--              <div class="what-order-create__desk">-->
<!--                {{ $t('ordersInbound_FBADesk.localization_value.value') }}-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->

<!--      </template>-->

    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import {ORDER_INBOUND_TYPE} from "../../../../../staticData/staticVariables";



  export default {
    name: "ShipmentCreatePopup",
    components: {
      Modal,
    },

    props: {
      fbm: {
        type: String,
        default: null,
      },
      fba: {
        type: String,
        default: null,
      },
      fbmReturn: {
        type: String,
        default: null,
      },
      fbaReturn: {
        type: String,
        default: null,
      },
      linkTarget: {
        type: [String, Boolean],
        default: false,
      },
    },

    data(){
      return{
        ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      }
    },
  }

</script>

<style lang="scss" scoped>

</style>
