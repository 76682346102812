<template>
  <div class="fragment">
    <template v-if="$store.getters.getReturnGoods.length > 0 && $store.getters.getReturnGoodsLoading !== true">
    <div class="custom-row pt-2">
      <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
           v-for="(item, index) in $store.getters.getReturnGoods" :key="index">
        <div class="fragment"
             style="height: 100%"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['returnGoods_sendToWarehouse','common_edit','common_delete','returnGoods_Trash',])"></div>
          <Item
              :img="item.image_files.length > 0 ? `data:${item.image_files[0].extension};base64,` + item.image_files[0].file : '/img/trash-group/unidentified-returns-img.jpg'"
              :images="item.image_files"
          >
            <template slot="head">
              <router-link
                  v-if="isTypeUnProcessed(typeReturned)"
                  :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/' + item.id"
                  class="case-cart__title btn-style">
                #{{ item.id }}
              </router-link>
              <router-link
                  v-else
                  :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + item.id"
                  class="case-cart__title btn-style">
                #{{ item.id }}
              </router-link>

              <div class="case-cart__status"
                   v-if="!isTypeProcessed(typeReturned) && !isTypeTrash(typeReturned)">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="alone more-180 bottom right">
                  <template slot="item">
                    <LinkButton
                        v-if="!item.order_inbound && !item.order_fbm && !item.return_document"
                        :value="$t('returnGoods_sendToWarehouse.localization_value.value')"
                        :type="'house'"
                        @click.native="changeShipmentCreatePopup(true, item.id, item.user_id, item.tracking_number, item.warehouse_storage ? item.warehouse_storage.id : false)"
                    />
                  </template>
                  <template
                      v-if="isAdmin && isTypeUnProcessed(typeReturned)"
                      slot="item">
                    <LinkButton
                        :value="$t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/' + item.id"
                    />
                  </template>
                  <template slot="item"
                            v-if="isAdmin">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeReturnedPackage(item.id, index)"
                    />
                  </template>
                  <template slot="item"
                            v-if="(isAdmin || isTypeMyReturns(typeReturned)) && !isTypeTrash(typeReturned) && !item.order_inbound && !item.order_fbm && !item.return_document">
                    <LinkButton
                        :value="$t('returnGoods_Trash.localization_value.value')"
                        :type="'delete'"
                        @click.native="toTrashReturnedPackage(item.id, index)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </template>
            <template slot="content">
              <div class="case-cart__content-block">
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></div>
                      {{$t('returnGoods_shipped.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{ item.delivery_date | moment("DD MMM, YYYY") }}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
                      {{$t('returnGoods_trackingNumber.localization_value.value')}}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper>
                        <template slot="text"><span class="text-ellipsis tracking-number">{{ item.tracking_number }}</span></template>
                        <template slot="title">{{ item.tracking_number }}</template>
                      </TooltipTextHelper>
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_search'])"></div>
                      {{$t('returnGoods_search.localization_value.value')}}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper v-if="item.modelInstance">
                        <template slot="text"><span class="text-ellipsis tracking-number"><ValueHelper :value="item.modelInstance.tracking_number"/></span></template>
                        <template slot="title"><ValueHelper :value="item.modelInstance.tracking_number"/></template>
                      </TooltipTextHelper>
                      <TooltipTextHelper v-else-if="item.starter_track">
                        <template slot="text"><span class="text-ellipsis tracking-number"><ValueHelper :value="item.starter_track"/></span></template>
                        <template slot="title"><ValueHelper :value="item.starter_track"/></template>
                      </TooltipTextHelper>
                      <template v-else>
                        —
                      </template>
                    </div>
                  </div>
                  <div class="case-cart__col custom-col"
                       v-if="!isTypeTrash(typeReturned)"
                  >
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_storage'])"></div>
                      {{$t('returnGoods_storage.localization_value.value')}}
                    </div>
                    <div class="case-cart__content word-break">
                      <ValueHelper
                          :value="item"
                          :deep="'warehouse_storage.name'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template slot="contentFooter">
              <div class="case-cart__content-block">
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['common_user'])"></div>
                      {{ $t('common_user.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <TableUserColumn
                          v-if="isAdmin"
                          :item="item"
                      />
                    </div>
                  </div>
                </div>
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_comment'])"></div>
                      {{ $t('returnGoods_comment.localization_value.value') }}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper>
                        <template slot="text"><span class="text-ellipsis">{{ item.comment }}</span></template>
                        <template slot="title">{{ item.comment }}</template>
                      </TooltipTextHelper>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template slot="footer">

              <template v-if="item.order_inbound">
                <div class="case-cart__btn w-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['returnGoods_Shipment','ordersInbound_statusCompleted', 'ordersInbound_statusProgress'])"></div>
                  <router-link
                      :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                    getOrderType(item.order_inbound['fulfillment'], item.order_inbound['return']).value + '/' + item.order_inbound.id"
                      :target="'_blank'">
                    <CaseCartButton
                        :value="getInboundStatus(item)"
                        :disabled="!isAdmin"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </template>

              <template v-else-if="item.order_fbm">
                <div class="case-cart__btn w-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['returnGoods_Order','fbm_inProgress','fbm_completed',])"></div>
                  <router-link
                      :to="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_fbm.order_type_id).name + '/' + item.order_fbm.id"
                      :target="'_blank'">
                    <CaseCartButton
                        :value="getFBMStatus(item)"
                        :disabled="!isAdmin"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </template>

              <template v-else-if="item.return_document">
                <div class="case-cart__btn w-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['returnGoods_CreatedMeest',])"></div>
                  <router-link
                      :to="$store.getters.GET_PATHS.ordersMeestReturns + '/' + item.return_document.id"
                      :target="'_blank'">
                    <CaseCartButton
                        :value="getMeestStatus(item)"
                        :disabled="!isAdmin"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </template>

              <template v-else>

                <template
                    v-if="isAdmin && isTypeTrash(typeReturned)">
                  <div class="case-cart__btn ml-auto"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_delete'])"></div>
                    <CaseCartButton
                        class="secondary"
                        :value="$t('common_delete.localization_value.value')"
                        @click.native="removeReturnedPackage(item.id, index)"
                    >
                    </CaseCartButton>
                  </div>
                  <div class="case-cart__btn ml-auto"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['returnGoods_TrashOut'])"></div>
                    <CaseCartButton
                        :value="$t('returnGoods_TrashOut.localization_value.value')"
                        @click.native="toTrashReturnedPackage(item.id, index, false)"
                    >
                    </CaseCartButton>
                  </div>
                </template>

                <template v-else>
                  <div class="case-cart__btn ml-auto"
                       v-if="item.warehouse_storage_id !== CAMBRIDGE_WAREHOUSE.storage_id && item.warehouse_storage_id !== LODZ_WAREHOUSE.storage_id"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['returnGoods_MeestToUkraine'])"></div>
                    <CaseCartButton
                        class="secondary"
                        :value="$t('returnGoods_MeestToUkraine.localization_value.value')"
                        @click.native="confirmCreateMeest(item.id, index)"
                    >
                    </CaseCartButton>
                  </div>
                  <div class="case-cart__btn ml-auto"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['returnGoods_ReSend'])"></div>
                    <router-link
                        v-if="item.warehouse_storage && item.warehouse_storage.id === 1"
                        :to="$store.getters.GET_PATHS.ordersFBMCreate + `/consolidation?idReturned=${item.id}&returnedUserId=${item.user_id}`"
                        :target="'_blank'">
                      <CaseCartButton
                          :value="$t('returnGoods_ReSend.localization_value.value')"
                      >
                      </CaseCartButton>
                    </router-link>
                    <router-link
                        v-else
                        :to="$store.getters.GET_PATHS.ordersFBMCreate + `/warehouse?idReturned=${item.id}&returnedUserId=${item.user_id}`"
                        :target="'_blank'">
                      <CaseCartButton
                          :value="$t('returnGoods_ReSend.localization_value.value')"
                      >
                      </CaseCartButton>
                    </router-link>
                  </div>
                </template>



              </template>
            </template>
          </Item>
        </div>
      </div>
    </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getReturnGoodsCommonList.next_page_url !== null && $store.getters.getReturnGoods.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getReturnGoodsCommonList.total - $store.getters.getReturnGoodsForPage < $store.getters.getReturnGoodsForPage ?
                  $store.getters.getReturnGoodsCommonList.total - $store.getters.getReturnGoodsForPage:
                  $store.getters.getReturnGoodsForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getReturnGoodsLoading === false && $store.getters.getReturnGoods.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <RequestReturnPackagePopup
      v-if="isModalReturnPackagePopup"
      @close="changeReturnPackagePopup(false)"
    />


    <ShipmentCreatePopup
        v-if="isModalShipmentCreatePopup"
        :linkTarget="'_blank'"
        :fbmReturn="$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE.FBMReturn.value + `?idReturned=${idReturned}&returnedUserId=${returnedUserId}&returnedTrack=${returnedTrackNumber}&strgId=${strId}`"
        :fbaReturn="$store.getters.GET_PATHS.ordersShipmentWarehouseCreate + ORDER_INBOUND_TYPE.FBAReturn.value + `?idReturned=${idReturned}&returnedUserId=${returnedUserId}&returnedTrack=${returnedTrackNumber}&strgId=${strId}`"
        @close="changeShipmentCreatePopup(false)"
    />

  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import RequestReturnPackagePopup from "../../../../popups/RequestReturnPackagePopup/RequestReturnPackagePopup";
  import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "@/components/coreComponents/NoResult/NoResult";
  import ManagerButton from "@/components/UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
  import ShipmentCreatePopup
    from "@/components/modules/OrdersInboundModule/popups/ShipmentCreatePopup/ShipmentCreatePopup";
  import {
    CAMBRIDGE_WAREHOUSE,
    FBM_STATUSES, LODZ_WAREHOUSE, MEEST_STATUSES_ARRAY,
    ORDER_INBOUND_STATUS,
    ORDER_INBOUND_TYPE
  } from "@/staticData/staticVariables";
  import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import Item from "@/components/modules/ReturnGoodsModule/components/chunks/Item/Item";
  import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
  import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ReturnedGoodsOfUserTable",
    components: {
      ValueHelper,
      TooltipTextHelper,
      TableUserColumn,
      ShipmentCreatePopup,
      LinkButton,
      ManagerButton,
      NoResult,
      ShowMore,
      Item,
      CaseCartButton,
      RequestReturnPackagePopup,
    },

    mixins: [returnGoodsMixin, ordersInboundMixin, FBMMixinsHelper],

    props: {
      typeReturned: String,
      countFilterParams: Number,
    },

    data(){
      return{
        isModalReturnPackagePopup: false,
        isModalShipmentCreatePopup: false,
        ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
        FBM_STATUSES: FBM_STATUSES,

        idReturned: null,
        returnedUserId: null,
        returnedTrackNumber: null,
        strId: null,

        CAMBRIDGE_WAREHOUSE: CAMBRIDGE_WAREHOUSE,
        LODZ_WAREHOUSE: LODZ_WAREHOUSE,
        MEEST_STATUSES_ARRAY: MEEST_STATUSES_ARRAY,
      }
    },

    methods:{

      confirmCreateMeest(id, index) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.createMeest(id, index)

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

      createMeest(id, index) {
        this.$store.dispatch('createReturnGoodsDocuments', {'return_parcel_id': id}).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordCreated')
              if(this.isTypeUnProcessed(this.typeReturned)){
                let returnedPackage = this.$store.getters.getReturnGoods
                returnedPackage.splice(index, 1)

                if(this.getRespData(response)?.id) {
                  this.$router.push(this.$store.getters.GET_PATHS.ordersMeestReturns + '/' + this.getRespData(response)?.id)
                }
                return
              }
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeShipmentCreatePopup(val, id, userId, trackNumber, strId = false){
        this.isModalShipmentCreatePopup = val
        this.idReturned = id
        this.returnedUserId = userId
        this.returnedTrackNumber = trackNumber
        if (strId) {
          this.strId = strId
        }
      },

      changeReturnPackagePopup(val){
        this.isModalReturnPackagePopup = val
      },

      getInboundStatus(item) {
        let status = item.order_inbound.status_id === ORDER_INBOUND_STATUS.STATUS_COMPLETE ?
            this.$t('ordersInbound_statusCompleted.localization_value.value') :
            this.$t('ordersInbound_statusProgress.localization_value.value')
        return `
        ${this.$t('returnGoods_Shipment.localization_value.value')}
        №${item.order_inbound_id} (${item.order_inbound.tracking_number ? item.order_inbound.tracking_number : ' : '}) ${status}`
      },

      getFBMStatus(item) {
        let status = item.order_fbm.status === FBM_STATUSES.FBM_COMPLETED_STATUS.value ?
            this.$t('fbm_completed.localization_value.value') :
            this.$t('fbm_inProgress.localization_value.value')
        return `
        ${this.$t('returnGoods_Order.localization_value.value')}
        ${item.order_fbm_id} ${item.order_fbm.tracking_number ? item.order_fbm.tracking_number : ' : '} ${status}`
      },

      getMeestStatus(item) {
        let status = this._.find(MEEST_STATUSES_ARRAY, {value: item.return_document.status})
        return `
         ${this.$t('returnGoods_CreatedMeest.localization_value.value')}
         ${item.return_document.id} : (${item.return_document.tracking_number ? item.return_document.tracking_number : ''}) ${this.$t(status.translation + '.localization_value.value')}`
      },
    }
  }
</script>

<style scoped>

.text-ellipsis{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis.tracking-number{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>