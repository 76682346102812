import { render, staticRenderFns } from "./RequestReturnGoodsListUser.vue?vue&type=template&id=de156412&scoped=true"
import script from "./RequestReturnGoodsListUser.vue?vue&type=script&lang=js"
export * from "./RequestReturnGoodsListUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de156412",
  null
  
)

export default component.exports