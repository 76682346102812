<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_Id'])"></span>
        <DefaultInput
            :label="$t('returnGoods_Id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></span>-->
<!--        <DatePickerDefault-->
<!--            :label="$t('returnGoods_shipped.localization_value.value')"-->
<!--            v-model="date"-->
<!--        >-->
<!--          <template slot="body">-->
<!--            <date-picker-->
<!--                v-model="date"-->
<!--                ref="datePicker"-->
<!--                valueType="format"-->
<!--                range-->
<!--                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                :format="'MM/DD/YY'"-->
<!--                :placeholder="'mm/dd/yy'"-->
<!--            ></date-picker>-->
<!--          </template>-->
<!--        </DatePickerDefault>-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-if="$store.getters.getIsAdminRights === 'admin'"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>-->
<!--        <SearchSelect-->
<!--            :options="options"-->
<!--            :label="$t('common_user.localization_value.value')"-->
<!--            :optionsLabel="'email.contacts[0]'"-->
<!--            :functionSearch="functionSearch"-->
<!--            @change="changeUsersFilter"-->
<!--            :selected="userName"-->
<!--            :typeSelect="'users'"-->
<!--            :userEmail="true"-->
<!--        />-->
<!--      </div>-->


<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></span>-->
<!--        <DefaultInput-->
<!--            :label="$t('returnGoods_trackingNumber.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="trackingNumber"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['returnGoods_fromUndefinedReturn', 'returnGoods_fromUndefinedReturnYes'])"></span>
        <DefaultSelect
            :label="$t('returnGoods_fromUndefinedReturn.localization_value.value')"
            :options="fromUndefinedReturnOptions"
            :optionsLabel="'name'"
            :selected="getFromUndefinedReturn"
            @change="changeFromUndefinedReturn"
        />
      </div>

      <div v-if="isAdmin" class="table-filter__item">
        <DefaultSelect
            class="w-100"
            :optionsLabel="'name'"
            :options="optionsStorage"
            :label="$t('productsReport_Storage.localization_value.value')"
            :selected="_.find(optionsStorage, {id: parseInt(storage)})"
            @change="changeStorageFilter"
        />
      </div>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
// import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
// import DatePicker from 'vue2-datepicker'
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import {usersFunc} from "@/mixins/usersMixins/usersFunc";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
// import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";

export default {
  name: "RequestReturnGoodsFilter",
  components: {
    DefaultSelect,
    // SearchSelect,
    FilterBlock,
    MainButton,
    // DatePickerDefault,
    // DatePicker,
    DefaultInput,
  },

  mixins: [filterFunc, usersFunc],

  props: {
    navTabs: Object,
    showFilter: Boolean,
    filterGetParams: Object,
    selectHandmade: Array,
  },

  computed: {
    getFromUndefinedReturn() {
      let foundSelected = this._.find(this.fromUndefinedReturnOptions, {value: this.fromUndefinedReturn})
      return foundSelected ? foundSelected : ''
    },
  },

  data() {
    return {
      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
      id: this.filterGetParams.id ? this.filterGetParams.id : '',
      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
      fromUndefinedReturn: this.filterGetParams.fromUndefinedReturn ? this.filterGetParams.fromUndefinedReturn : '',
      storage: this.filterGetParams.storage ? this.filterGetParams.storage : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      options: [],
      optionsStorage: [],

      filterCounts: [
        'date',
        'id',
        'trackingNumber',
        'fromUndefinedReturn',
        'storage',

        'userName',
        'userId',
      ],

      fromUndefinedReturnOptions: [
        {
          name: this.$t('returnGoods_fromUndefinedReturnAll.localization_value.value'),
          value: ''
        },
        {
          name: this.$t('returnGoods_fromUndefinedReturnYes.localization_value.value'),
          value: '1'
        },
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
      this.id = newVal.id ? newVal.id : ''
      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
      this.fromUndefinedReturn = newVal.fromUndefinedReturn ? newVal.fromUndefinedReturn : ''
      this.storage = newVal.storage ? newVal.storage : ''

      this.userName = newVal.userName ? newVal.userName : ''
      this.userId = newVal.userId ? newVal.userId : ''
    },

  },

  mounted() {
    this.getWarehouseStorages()
  },

  methods: {
    async getWarehouseStorages() {
      return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
        this.optionsStorage = this.getRespPaginateData(response)
      })
    },

    closeFilter() {
      this.$emit('close')
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')

      this.$emit('changeFilter', data)
    },

    changeFromUndefinedReturn(val) {
      this.fromUndefinedReturn = val.value
    },

    changeStorageFilter(val) {
      this.storage = val.id
    },
  },
}
</script>

<style scoped>

</style>
