<template>
  <RequestReturnGoodsListUser
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
  />
</template>

<script>
  import RequestReturnGoodsListUser from "./RequestReturnGoodsListUser/RequestReturnGoodsListUser";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "RequestReturnGoodsList",
    components: {
      RequestReturnGoodsListUser,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getReturnGoodsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterTrackingNumber: '',
        filterFromUndefinedReturn: '',
        filterStorage: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      if(this.isAdmin && !this.$route.query.storage && this?.$store?.getters?.getUserProfile?.warehouse_storage){
        this.$route.query.storage = this.$store.getters.getUserProfile?.warehouse_storage?.id;
      }

      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          trackingNumber: this.$route.query.trackingNumber,
          fromUndefinedReturn: this.$route.query.fromUndefinedReturn,
          storage: this.$route.query.storage,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextReturnGoodsPage', true)
        this.$store.dispatch('fetchRequestReturnGoods', url).then(() => {
          this.$store.commit('setNextReturnGoodsPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'ReturnRequestId',
              filterFromUndefinedReturn: 'relatedToUndefined',
              filterStorage: 'WarehouseStorageId',
              // filterUserId: 'userId',
              // filterTrackingNumber: 'transactionTrackingNumber',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('between_created_at', date)
        }

        // myQuery.where('ReturnParcelStatus', 'undefined')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    },

  }
</script>

<style scoped>

</style>