<template>
  <div class="fragment">

    <div class="content-top-line position-relative">
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block"
           v-if="isTypeProcessed(typeReturned)"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
        <router-link :to="$store.getters.GET_PATHS.problemsReturnGoodsCreation">
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="'+ ' + $t('common_AddNew.localization_value.value')"
          >
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {RETURN_GOODS_TYPES} from "@/staticData/staticVariables";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";


export default {
  name: "ReturnedGoodsOfUserHead",
  components: {
    MainButton,
    ToggleFilterButton,
  },

  mixins: [returnGoodsMixin],

  props: {
    typeReturned: String,
    showFilter: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  data() {
    return {
      RETURN_GOODS_TYPES: RETURN_GOODS_TYPES,
    }
  },

  methods: {}
}
</script>

<style scoped>

</style>

