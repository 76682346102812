<template>
  <div class="fragment">

    <div class="content-top-line position-relative">
      <div class="content-tabs content-tabs--separator"></div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
    </div>
  </div>
</template>

<script>
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


export default {
  name: "RequestReturnGoodsHead",
  components: {
    ToggleFilterButton,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>

</style>

