<template>
  <div>
    <RequestReturnGoodsHead
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <RequestReturnGoodsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <RequestReturnGoodsList
          :countFilterParams="countFilterParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import RequestReturnGoodsHead from "./RequestReturnGoodsHead/RequestReturnGoodsHead";
  import RequestReturnGoodsList from "./RequestReturnGoodsList/RequestReturnGoodsList";
  import RequestReturnGoodsFilter from "./RequestReturnGoodsFilter/RequestReturnGoodsFilter";

  export default {
    name: "RequestReturnGoodsListUser",

    components: {
      RequestReturnGoodsHead,
      RequestReturnGoodsList,
      RequestReturnGoodsFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

    }
  }
</script>

<style scoped>

</style>


