<template>
  <div class="fragment">
    <template v-if="$store.getters.getRequestReturnGoods.length > 0 && $store.getters.getRequestReturnGoodsLoading !== true">
      <div class="custom-row pt-2">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getRequestReturnGoods" :key="index">
          <Item
              :img="item.image_files.length > 0 ? `data:${item.image_files[0].extension};base64,` + item.image_files[0].file : '/img/trash-group/unidentified-returns-img.jpg'"
              :images="item.image_files"
          >
            <template slot="head">
              <div class="case-cart__title btn-style"
                   @click="changeReturnPackagePopup(true, item.id)"
              >
                #{{ item.id }}
              </div>

            </template>

            <template slot="content">
              <div class="case-cart__content-block">
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></div>
                      {{ $t('returnGoods_shipped.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      {{ item.created_at | moment("DD MMM, YYYY") }}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_returnPackageId'])"></div>
                      {{ $t('returnGoods_returnPackageId.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <router-link
                          class="table-link btn-style"
                          :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + item.return_parcel_id">
                        {{ item.return_parcel_id }}
                      </router-link>
                    </div>
                  </div>

                </div>
              </div>
            </template>

            <template slot="contentFooter">
              <div class="case-cart__content-block">
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
                      {{ $t('common_user.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <TableUserColumn
                          v-if="isAdmin"
                          :item="item"
                      />
                    </div>
                  </div>
                </div>
                <div class="case-cart__row custom-row p-0">
                  <div class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
                      {{ $t('returnGoods_comment.localization_value.value') }}
                    </div>
                    <div class="case-cart__content word-break">
                      <TooltipTextHelper>
                        <template slot="text"><span class="text-ellipsis">{{ item.comment }}</span></template>
                        <template slot="title">{{ item.comment }}</template>
                      </TooltipTextHelper>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Item>



        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getRequestReturnGoodsCommonList.next_page_url !== null && $store.getters.getRequestReturnGoods.length > 0"
              @click.native="$emit('showMore')"
              :count="$store.getters.getRequestReturnGoodsCommonList.total - $store.getters.getRequestReturnGoodsForPage < $store.getters.getRequestReturnGoodsForPage ?
                  $store.getters.getRequestReturnGoodsCommonList.total - $store.getters.getRequestReturnGoodsForPage:
                  $store.getters.getRequestReturnGoodsForPage"
          />
        </div>
      </div>

    </template>

    <template v-if="$store.getters.getRequestReturnGoodsLoading === false && $store.getters.getRequestReturnGoods.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <RequestReturnPackagePopup
        v-if="isModalReturnPackagePopup"
        :requestReturnPackageId="requestReturnPackageId"
        @close="changeReturnPackagePopup(false)"
    />

  </div>
</template>

<script>
import Item from "../../../chunks/Item/Item";
import RequestReturnPackagePopup from "../../../../popups/RequestReturnPackagePopup/RequestReturnPackagePopup";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

export default {
  name: "RequestRequestReturnGoodsList",
  components: {
    TooltipTextHelper,
    TableUserColumn,
    NoResult,
    ShowMore,
    Item,
    RequestReturnPackagePopup,
  },

  mixins: [returnGoodsMixin],

  props: {
    countFilterParams: Number,
  },

  data() {
    return {
      isModalReturnPackagePopup: false,
      requestReturnPackageId: -1,
    }
  },

  methods: {
    changeReturnPackagePopup(val, id) {
      this.isModalReturnPackagePopup = val
      this.requestReturnPackageId = id
    }
  }
}
</script>

<style scoped>

.text-ellipsis{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis.tracking-number{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>